import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import Map from '../components/map'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {

  state = {
    name: '',
    phone: '',
    email: '',
    enquiry: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail'}));
  }

  render() {
    return (
      <>
        { this.state.result !== 'success' &&
          <form 
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" onChange={this.handleChange} />
            <input type='text' name='name' placeholder='Name' onChange={this.handleChange} required />
            <input type='text' name='phone' placeholder='Phone' onChange={this.handleChange} required />
            <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required />
            <textarea name='enquiry' placeholder='Enquiry' onChange={this.handleChange} />
            <button type='submit'>Send</button>
          </form>
        }
        { this.state.result === 'success' &&
          <>
            <div className="form__success" dangerouslySetInnerHTML={{__html: 'Thanks for getting in contact.  A member of our team will be in touch soon.'}} />
          </>
        }
      </>
    )
  }
}

class ContactPage extends Component {

  renderImage(image) {
    if (image === null || !image.localFile) return
    return <img src={image.localFile.childImageSharp.original.src} alt={image.alt_text} />
  }

  render() {
    let { page } = this.props.data
    let { options } = this.props.data.wordpressAcfOptions
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass="contact" description={page.yoast_meta.yoast_wpseo_metadesc} />
        <section className='contact__main'>
          <div className='contact__main__inner'>
            <h1>{ page.acf.title }</h1>
            <p>
              <strong>Melbourne</strong><br />
              <span dangerouslySetInnerHTML={{__html: options.address.split('\n').join('<br />') }} />
              <a href='//' className='small'>View on Maps</a>
            </p>

            <p>
              T <a href={`tel:${ options.phone }`}>{ options.phone }</a><br />
              E <a href={`mailto:${ options.email }`}>{ options.email }</a>
            </p>

            <ContactForm />

          </div>
          <div className='contact__main__map'>
            <Map lat={options.latitude} lng={options.longitude} />
            <picture>
              { this.renderImage(page.acf.image) }
            </picture>
          </div>
        </section>
        <p className='contact__text'>{ page.acf.text }</p>
      </>
    )
  }
}

export default ContactPage

export const query = graphql`
  query {
    page: wordpressPage(wordpress_id: {eq: 94}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        text
        image {
          ...original
        }
      }
    }

    wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
      options {
        address
        latitude
        longitude
        email
        phone
      }
    }
  }
`
